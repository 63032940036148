import React from 'react';

import PartDesignHelper from '../part-design-helper';
import PartFooter from '../part-footer';
import PartHeader from '../part-header';

import PartAboutUs from './part-about-us/part-about-us';
import PartBannerConnectingTalentAndCompanies from './part-banner-main';
import PartOurAdvantages from './part-our-advantages/part-our-advantages';
import PartOurServices from './part-our-services';
import Partreviews from './part-reviews';
import PartTeam from './part-team';

import styles from './page-home.module.scss';

import DesktopImage from './design/Desktop.jpg';
import MobileImage from './design/Mobile.jpg';
import TabletImage from './design/Tablet.jpg';

export default function PageHome() {
  return (
    <div className={styles.container}>
      <PartDesignHelper
        designs={{
          DesktopImage,
          MobileImage,
          TabletImage,
        }}
      />
      <PartHeader />
      <main>
        <PartBannerConnectingTalentAndCompanies className={styles.banner} />
        <PartOurAdvantages />
        <PartOurServices />
        <PartAboutUs />
        <PartTeam />
        <Partreviews />
      </main>
      <PartFooter />
    </div>
  );
}
