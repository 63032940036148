import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import aboutUsImage from './assets/aboutUsImage.png';
import aboutUsImagePlaceholder from './assets/aboutUsImage.png?placeholder';
import JetLexLogoLong from './assets/JetLexLogoLong.svg';

import styles from './part-about-us.module.scss';

import data from './data';

export default function PartAboutUs() {
  return (
    <section className={styles.container} id="aboutus">
      <div className={styles.aboutUsContainer}>
        <h2 className="sectionTitle">Про нас</h2>
        <div className={styles.wrap}>
          <div className={styles.contentWrap}>
            {data.map((item) => (
              <p key={item} className={styles.text}>
                {item.importantText && (
                  <i className={styles.importantText}>{item.importantText}</i>
                )}
                {item.text}
              </p>
            ))}
          </div>
          <div className={styles.aboutUsImageWrap}>
            <PartLazyImage
              src={aboutUsImage}
              placeholder={aboutUsImagePlaceholder}
            />
          </div>
        </div>
        <div className={styles.logoWrap}>
          <JetLexLogoLong className={styles.logo} />
        </div>
      </div>
    </section>
  );
}
