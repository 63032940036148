import React from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import PartLazyImage from '../../part-lazy-image';

import JetLexLogoLong from './assets/JetLexLogoLong.svg';

import styles from './part-team.module.scss';

import data from './data';

export default function PartTeam() {
  return (
    <section className={styles.container} id="team">
      <div className={styles.teamContainer}>
        <h2 className="sectionTitle">Команда</h2>
        <div className={styles.teamList}>
          <Swiper
            className={styles.swiperContainer}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            {...{
              centeredSlides: true,
              centeredSlidesBounds: true,
              slidesPerView: 'auto',
              spaceBetween: 30,
              breakpoints: {
                0: {
                  enabled: true,
                  slidesPerView: 1,
                  spaceBetween: 10,
                  pagination: {
                    enabled: true,
                  },
                },
              },
            }}
          >
            {data.map(
              ({
                id,
                personName,
                title,
                description,
                image,
                imagePlaceholder,
              }) => (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line react/no-array-index-key, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <SwiperSlide className={styles.swiperSlide} key={id}>
                  <div className={styles.card}>
                    <PartLazyImage
                      className={styles.cardImage}
                      src={image}
                      placeholder={imagePlaceholder}
                    />
                    <div className={styles.cardContent}>
                      <div className={styles.logoWrap}>
                        <JetLexLogoLong className={styles.logo} />
                      </div>
                      <h3 className={styles.cardName}>{personName}</h3>
                      <hr className={styles.cardHr} />
                      <p className={styles.cardTitle}>{title}</p>
                      {description.map((item) => (
                        <p key={item + id} className={styles.cardDescription}>
                          {item}
                        </p>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
