/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import IconCreativity from './assets/icon-creativity.svg';
import IconExperience from './assets/icon-experience.svg';
import IconOrientation from './assets/icon-orientation.svg';
import IconSpecialization from './assets/icon-specialization.svg';
import imageCreativity from './assets/image-creativity.png';
import imageCreativityPlaceholder from './assets/image-creativity.png?placeholder';
import imageExperience from './assets/image-experience.png';
import imageExperiencePlaceholder from './assets/image-experience.png?placeholder';
import imageOrientation from './assets/image-orientation.png';
import imageOrientationPlaceholder from './assets/image-orientation.png?placeholder';
import imageSpecialization from './assets/image-specialization.png';
import imageSpecializationPlaceholder from './assets/image-specialization.png?placeholder';

export default [
  {
    slug: 'Orientation',
    title: 'Бізнес-орієнтованість',
    icon: <IconOrientation />,
    image: imageOrientation,
    imagePlaceholder: imageOrientationPlaceholder,
  },
  {
    slug: 'Experience',
    title: 'Багаторічний досвід роботи',
    icon: <IconExperience />,
    image: imageExperience,
    imagePlaceholder: imageExperiencePlaceholder,
  },
  {
    slug: 'Specialization',
    title: 'Вузька спеціалізація',
    icon: <IconSpecialization />,
    image: imageSpecialization,
    imagePlaceholder: imageSpecializationPlaceholder,
  },
  {
    slug: 'Creativity',
    title: 'Креативність',
    icon: <IconCreativity />,
    image: imageCreativity,
    imagePlaceholder: imageCreativityPlaceholder,
  },
];
