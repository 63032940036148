import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import styles from './part-our-advantages.module.scss';

import data from './data';

export default function PartOurAdvantages() {
  return (
    <section className={styles.container} id="advantages">
      <div className={styles.advantageContainer}>
        <h2 className="sectionTitle">Переваги</h2>
        <div className={styles.wrap}>
          {data.map((advantage) => (
            <div key={advantage.slug} className={styles.advantage}>
              <div className={styles.advantageIcon}>{advantage.icon}</div>
              <h3 className={styles.advantageTitle}>{advantage.title}</h3>
              <PartLazyImage
                src={advantage.image}
                placeholder={advantage.imagePlaceholder}
                className={styles.advantageImage}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
