export default [
  {
    importantText: 'JetLex',
    text: '— юридична фірма, яка спеціалізується на супроводжені діяльності банків та фінансових установ, роботі з проблемними активами корпоративних клієнтів банків та фінансових компаній, врегулюванні кредиторської та дебіторської заборгованості суб’єктів господарювання, супроводження судових спорів будь-якої підвідомчості та складності.',
  },
  {
    importantText: '',
    text: 'Ми об’єднуємо спеціалістів з багаторічним досвідом роботи в сфері фінансів та судового процесу, що дозволяє нам швидко та ефективно вирішувати поставлені задачі.',
  },
  {
    importantText: '',
    text: 'Наша місія - вирішення юридичних та процедурних питань замість клієнтів, що дозволяє власникам та керівникам компаній не відволікатись від бізнес процесів.',
  },
];
