import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import {
  companyAddress,
  companyPhone,
  companyEmail,
  headerMenu,
  socialLinks,
} from '../../../site-config';
import { designElementsParentContainerClass } from '../../part-design-elements-container';

import IconTelegram from './assets/icon-telegram.svg';
import IconViber from './assets/icon-viber.svg';
import IconWhatsApp from './assets/icon-whatsapp.svg';

import styles from './part-mobile-menu.module.scss';

const companyPhoneLink = companyPhone?.match(/\+?\d+/g).join('');

export default function PartMobileMenu({ id, className }) {
  return (
    <div
      className={cx(
        styles.container,
        className,
        designElementsParentContainerClass,
      )}
      id={id}
    >
      <div className={styles.content}>
        <div className={styles.menuContainer}>
          {headerMenu.map(({ title, link }) => (
            <Link
              key={`mobile-menu${title} + ${link}`}
              href={link}
              className={styles.menuLink}
            >
              {title}
            </Link>
          ))}
        </div>
        <div className={styles.contacts}>
          <ul className={styles.contactsDetailes}>
            <li className={styles.contactsDetailesItem}>
              <a
                href={`tel:${companyPhoneLink}`}
                rel="nofollow noreferrer"
                target="_blank"
                className={styles.menuContactLink}
              >
                <span className={styles.menuContactText}>{companyPhone}</span>
              </a>
            </li>
            <li className={styles.contactsDetailesItem}>
              <a
                href={`email:${companyPhoneLink}`}
                rel="nofollow noreferrer"
                target="_blank"
                className={styles.menuContactLink}
              >
                <span className={styles.menuContactText}>{companyEmail}</span>
              </a>
            </li>
          </ul>
          <p className={styles.contactsAddress}>{companyAddress}</p>
          <ul className={styles.socials}>
            {socialLinks.map(({ slug, link }) => (
              <li key={`mobile-social-menu${slug} + ${link}`}>
                <a
                  className={styles.socialButton}
                  href={link}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <div
                    className={cx(
                      styles.socialIcon,
                      styles[`socialIcon_${slug}`],
                    )}
                  >
                    {slug === 'telegram' && <IconTelegram />}
                    {slug === 'viber' && <IconViber />}
                    {slug === 'whatsapp' && <IconWhatsApp />}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

PartMobileMenu.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};
