/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import IconOne from './assets/icon1.svg';
import IconTwo from './assets/icon2.svg';
import IconThree from './assets/icon3.svg';
import IconFour from './assets/icon4.svg';

export default [
  {
    slug: 'One',
    title: 'Юридичне супроводження',
    icon: <IconOne />,
    content:
      'Юридичне супроводження діяльності банків та фінансових установ',
    list: [
      'реєстрація юридичних осіб, отримання ліцензій, погодження статутів та змін до них, повідомлення і погодження призначення керівників та інших працівників, відкриття відокремлених підрозділів;',
      'супроводження правочинів купівлі-продажу, погодження набуття істотної участі, внесення відповідних змін до реєстрів;',
      'участь у розробленні бізнес процесів надання фінансових послуг, розробка та погодження договорів та внутрішніх документів (протоколи зборів органів управління, правила надання фінансових послуг, щодо здійснення внутрішнього аудиту тощо);',
      'розкриття інформації на сайті, що підлягає обов’язковому оприлюдненню;',
      'правове супроводження перевірок контролюючих органів;',
      'правові консультації та вирішення поточних юридичних питань.',
    ],
  },
  {
    slug: 'Two',
    title: 'Проблемні активи',
    icon: <IconTwo />,
    content:
      'Робота з корпоративними кредитними портфелями, проблемними та іншими активами банків та фінансових установ:',
    list: [
      'юридичний аналіз прав вимоги (зокрема великих портфелів кредитів NPL), об’єктів нерухомості або інших активів з метою визначення цікавих активів для придбання (дью діл) та підготовка презентаційних матеріалів для залучення інвесторів (при необхідності);',
      'організація та супроводження участі в торгах, на яких реалізуються активи непрацюючих та/або державних банків;',
      'очистка та захист титулу права власності на придбані активи та захист придбаних активів у разі оскарження торгів;',
      'доведення кейсів NPL до отримання очікуваних інвесторами кеш рекавері або отримання майна на баланс;',
      'розроблення правової позиції, підготовка стратегії та тактики роботи з активами в суді;',
      'підготовка процесуальних документів та представництво інтересів в суді;',
      'звернення стягнення на майно.',
    ],
  },
  {
    slug: 'Three',
    title: 'Врегулювання заборгованості ',
    icon: <IconThree />,
    content:
      'Врегулювання кредиторської та дебіторської заборгованості, що виникає в процесі господарської діяльності підприємств:',
    list: [
      'підготовка плану досудового врегулювання та договірної бази;',
      'банківські, фінансові, договірні та корпоративні спори, податкові та інші спори з контролюючими органами, спори стосовно рухомого та нерухомого майна, інші спори в сфері господарської діяльності;',
      'організація взаємодії з боржниками, оцінювачами, нотаріусами, реєстраторами, адвокатами, приватними виконавцями, арбітражними керуючими та іншими.',
    ],
  },
  {
    slug: 'Four',
    title: 'Судові спори ',
    icon: <IconFour />,
    content:
      'Судові спори будь-якої підвідомчості та складності або досудове врегулювання:',
    list: [
      'розроблення правової позиції, підготовка стратегії і тактики судового процесу або досудового врегулювання; ',
      'звернення стягнення на майно (в тому числі іпотечне/заставне) в порядку судового провадження або досудового врегулювання;',
      'процесуальне представництво;',
      'виконавче провадження;',
      'банкрутство.',
    ],
  },
];
