import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import {
  footerMenu,
  socialLinks,
  companyPhone,
  companyPhoneSecond,
  companyEmail,
  companyAddress,
} from '../../site-config';

import IconTelegram from './assets/icon-telegram-footer.svg';
import IconViber from './assets/icon-viber.svg';
import IconWhatsApp from './assets/icon-whatsapp.svg';
import LogoJFooter from './assets/logo-j-footer.svg';
import LogoXFooter from './assets/logo-x-footer.svg';
import FullLongLogo from './assets/LogoJetLexFool.svg';

import styles from './part-footer.module.scss';

const companyPhoneLink = companyPhone?.match(/\+?\d+/g).join('');
const companyPhonesecondLink = companyPhoneSecond?.match(/\+?\d+/g).join('');

let copyrightYearsText = '2023';
const yearNow = new Date().getFullYear();
if (yearNow > +copyrightYearsText) {
  copyrightYearsText += `-${String(yearNow)}`;
}
const handleClick = (event) => {
  if (!event.target?.getAttribute('href')) return;

  event.preventDefault();
  const href = event.target.getAttribute('href').split('#')[1];
  const targetElement = document.getElementById(href);
  const headerHeight = document.getElementById('header').offsetHeight;
  const topOffset = targetElement.offsetTop - headerHeight;
  window.scrollTo({
    top: topOffset,
    left: 0,
    behavior: 'smooth',
  });
  window.history.replaceState({}, '', event.target.getAttribute('href'));
};

export default function PartFooter() {
  return (
    <footer className={styles.container}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLogoWrap}>
          <Link href="/">
            <FullLongLogo className={styles.logo} />
          </Link>
        </div>
        <div className={styles.footerSocialContainer}>
          <ul className={styles.footerSocialsWrap}>
            {socialLinks.map(({ slug, link }) => (
              <li key={`mobile-social-menu${slug} + ${link}`}>
                <a href={link} target="_blank" rel="nofollow noreferrer">
                  <div
                    className={cx(
                      styles.footerSocialIcon,
                      styles[`footerSocialIcon_${slug}`],
                    )}
                  >
                    {slug === 'telegram' && <IconTelegram />}
                    {slug === 'viber' && <IconViber />}
                    {slug === 'whatsapp' && <IconWhatsApp />}
                  </div>
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.copyright}>
            ©
            {copyrightYearsText}
            {' '}
            JetLex. Розробка: Y007
          </div>
        </div>

        <div className={styles.footerContactContainer}>
          <a
            href={`tel:${companyPhoneLink}`}
            rel="nofollow noreferrer"
            target="_blank"
            className={styles.footerContactLink}
          >
            <span className={styles.footerContactText}>{companyPhone}</span>
          </a>
          <a
            href={`tel:${companyPhonesecondLink}`}
            rel="nofollow noreferrer"
            target="_blank"
            className={styles.footerContactLink}
          >
            <span className={styles.footerContactText}>{companyPhoneSecond}</span>
          </a>
          <a
            href={`email:${companyPhoneLink}`}
            rel="nofollow noreferrer"
            target="_blank"
            className={styles.footerContactLink}
          >
            <span className={styles.footerContactText}>{companyEmail}</span>
          </a>
          <p className={styles.footerContactsAddress}>{companyAddress}</p>
          <LogoJFooter className={styles.logoJFooter} />
          <LogoXFooter className={styles.logoXFooter} />
        </div>
        <div className={styles.footerMenuContainer}>
          {footerMenu.map(({ title, link }) => (
            <Link
              href={link}
              className={styles.footerMenuLink}
              key={`footer-menu${title} + ${link}`}
              onClick={handleClick}
            >
              {title}
            </Link>
          ))}
        </div>
      </div>
    </footer>
  );
}
