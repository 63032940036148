/* eslint-disable import/no-duplicates */
import cx from 'classnames';
import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import JetLexLogoLong from './assets/JetLexLogoLong.svg';
import LogoJ from './assets/logo-j.svg';
import LogoX from './assets/logo-x.svg';
import mainImageDesktop from './assets/mainImageDesktop.png';
import mainImageDesktopPlaceholder from './assets/mainImageDesktop.png?placeholder';
import mainImageMobile from './assets/mainImageMobile.png';
import mainImageMobilePlaceholder from './assets/mainImageMobile.png?placeholder';
import mainImageTablet from './assets/mainImageTablet.png';
import mainImageTabletPlaceholder from './assets/mainImageTablet.png?placeholder';

import styles from './part-banner-main.module.scss';

export default function PartBannerMain({ className }) {
  return (
    <section className={cx(styles.container, className)}>
      <div className={styles.bannerImageMainOuterWrap}>
        <div className={styles.bannerImageMainInnerWrap}>
          <PartLazyImage
            src={mainImageDesktop}
            placeholder={mainImageDesktopPlaceholder}
            className={cx(
              styles.bannerImageMainContainer,
              'visibleOnDesktopAndTablet',
            )}
          />
          <PartLazyImage
            src={mainImageTablet}
            placeholder={mainImageTabletPlaceholder}
            className={cx(styles.bannerImageMainContainer, 'visibleOnTablet')}
          />
          <PartLazyImage
            src={mainImageMobile}
            placeholder={mainImageMobilePlaceholder}
            className={cx(styles.bannerImageMainContainer, 'visibleOnMobilePhone')}
          />
          <LogoJ className={styles.logoJ} />
          <LogoX className={styles.logoX} />
        </div>
      </div>
      <div className={styles.mainTextContainer}>
        <JetLexLogoLong className={styles.logo} />
        <h1 className={styles.mainText}>Іноваційні рішення юридичних питань</h1>
      </div>
    </section>
  );
}
