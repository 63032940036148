import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { withQuery } from 'ufo';
import unwrapElement from 'unwrap-element';

import { headerMenu } from '../../site-config';

import LogoIcon from './assets/JetLexLogo.svg';
import PartMobileMenu from './part-mobile-menu';

import styles from './part-header.module.scss';

const mobileMenuQueryKey = 'mobileMenuOpened';

export default function PartHeader() {
  const router = useRouter();

  let mobileMenuButtonHref;
  let mobileMenuOpened;
  if (!router.query[mobileMenuQueryKey]) {
    // Menu closed, open menu
    mobileMenuButtonHref = withQuery(router.asPath, { [mobileMenuQueryKey]: 'true' });
    mobileMenuOpened = false;
  } else {
    // Menu opened, close menu and go back
    mobileMenuButtonHref = withQuery(router.asPath, { [mobileMenuQueryKey]: undefined });
    mobileMenuOpened = true;
  }

  const links = headerMenu
    .map((route) => ({
      ...route,
      active: router.pathname === route.link,
    }));

  const destroyUnwrapReference = useRef(null);

  useEffect(() => {
    if (mobileMenuOpened) {
      destroyUnwrapReference.current = unwrapElement('#mobileMenu', {
        bypassSelectorsOrNodes: ['body'],
      });
    } else {
      destroyUnwrapReference.current?.();
    }
    return () => {
      // код отписки от ресурса
      destroyUnwrapReference.current?.();
    };
  }, [mobileMenuOpened]);

  const handleClick = (event) => {
    if (!event.target?.getAttribute('href')) return;

    event.preventDefault();
    const href = event.target.getAttribute('href').split('#')[1];
    const targetElement = document.getElementById(href);
    const headerHeight = document.getElementById('header').offsetHeight;
    const topOffset = targetElement.offsetTop - headerHeight;
    window.scrollTo({
      top: topOffset,
      left: 0,
      behavior: 'smooth',
    });
    window.history.replaceState({}, '', event.target.getAttribute('href'));
  };

  return (
    <>
      <header className={cx(styles.headerBox, { [styles.mobileMenuOpened]: mobileMenuOpened })} id="header">
        <div className={styles.headerContainer}>
          <div className={styles.leftContainer}>
            <Link className={styles.logo} href="/">
              <LogoIcon />
            </Link>
          </div>
          <div className={styles.menuContainer}>
            {links.map(({ title, link, active }) => (
              <div key={`desktop-menu${title} + ${link}`}>
                <Link
                  href={link}
                  className={cx(styles.menuLink, { [styles.menuLinkActive]: active })}
                  onClick={handleClick}
                >
                  {title}
                </Link>
              </div>
            ))}
          </div>
          <div className={styles.rightContainer}>
            <Link
              className={cx(styles.burgerButton, { [styles.mobileMenuOpened]: mobileMenuOpened })}
              data-opened={mobileMenuOpened ? '' : undefined}
              href={mobileMenuButtonHref}
              shallow
            >
              <div className={styles.burgerIcon}>
                <i />
                <i />
                <i />
              </div>
            </Link>
          </div>
        </div>
      </header>
      <PartMobileMenu id="mobileMenu" className={styles.mobileMenu} />
    </>
  );
}
