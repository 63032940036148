const defaultSiteMenu = [
  /**
   * When you change routing here, you should change filenames in "pages" folder,
   * which also used as routing
   */
  {
    title: 'Переваги',
    link: '/#advantages',
  },
  {
    title: 'Послуги',
    link: '/#services',
  },
  {
    title: 'Про нас',
    link: '/#aboutus',
  },
  {
    title: 'Відгуки',
    link: '/#reviews',
  },
  {
    title: 'Команда',
    link: '/#team',
  },
];

export const headerMenu = defaultSiteMenu;

export const footerMenu = defaultSiteMenu;

export const socialLinks = [
  { slug: 'telegram', link: '' },
  { slug: 'viber', link: '' },
  { slug: 'whatsapp', link: '' },
];

export const companyPhone = '+38 (067) 5505500';
export const companyPhoneSecond = '+38 (067) 4449761';

export const companyEmail = 'jetlex.office@gmail.com';

export const companyAddress = 'Харківське шосе 19, оф.2005,\n Київ, 02090, Україна';

export const companyAddressOnPhone = 'Харківське шосе 19, оф.2005,\n Київ, 02090, Україна';
