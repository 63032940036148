/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import PartLazyImage from '../../part-lazy-image';

import Stars from './assets/stars.svg';

import styles from './part-reviews.module.scss';

import data from './data';

export default function PartReviews() {
  return (
    <section className={styles.container} id="reviews">
      <div className={styles.reviewsList}>
        <Swiper
          className={styles.swiperContainer}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          {...{
            centeredSlides: true,
            initialSlide: 3,
            breakpoints: {
              0: {
                enabled: true,
                slidesPerView: 1,
                spaceBetween: 10,
                initialSlide: 1,
                pagination: {
                  enabled: true,
                },
              },
              767: {
                initialSlide: 2,
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1700: {
                initialSlide: 3,
                slidesPerView: 4,
                spaceBetween: 30,
              },
            },
          }}
        >
          {data.map(
            ({
              id,
              firstName,
              lastName,
              company,
              reviews,
              image,
              imagePlaceholder,
            }) => (
              // eslint-disable-next-line max-len
              // eslint-disable-next-line react/no-array-index-key, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <SwiperSlide className={styles.swiperSlide} key={id}>
                <div className={styles.card}>
                  <div className={styles.cardImageWrap}>
                    <PartLazyImage
                      src={image}
                      placeholder={imagePlaceholder}
                    />
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.iconWrap}>
                      <Stars className={styles.icon} />
                    </div>
                    <p className={styles.cardName}>{firstName}</p>
                    <p className={styles.cardName}>{lastName}</p>
                    <p className={styles.cardCompany}>{company}</p>
                    {reviews.map((item, index) => (
                      <p
                        key={item + id + index}
                        className={styles.cardDescription}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </div>
    </section>
  );
}
