import cx from 'classnames';
import React from 'react';

import LogoBackground from './assets/LogoBackground.svg';

import styles from './part-our-services.module.scss';

import data from './data';

export default function PartOurServices() {
  return (
    <section className={styles.container} id="services">
      <div className={styles.servicesContainer}>
        <h2 className="sectionTitle">Послуги</h2>
        <ul className={styles.contentWrap}>
          {data.map((service) => (
            <li key={service.slug} className={styles.service}>
              <div className={styles.serviceTitleContainer}>
                <div className={styles.serviceIcon}>{service.icon}</div>
                <h3 className={styles.serviceTitle}>{service.title}</h3>
              </div>
              <p className={styles.serviceContent}>{service.content}</p>
              <ul className={styles.serviceContentList}>
                {service.list.map((item) => (
                  <li key={item} className={styles.serviceContentListItem}>
                    {item}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div className={cx(styles.background, 'visibleOnDesktopAndTablet')}>
        <LogoBackground />
      </div>
    </section>
  );
}
