import partnerOne from './assets/bezrodnii-oleksandr.png';
import partnerOnePlaceholder from './assets/bezrodnii-oleksandr.png?placeholder';
import partnerTwo from './assets/kokolskyi-serhii.png';
import partnerTwoPlaceholder from './assets/kokolskyi-serhii.png?placeholder';

export default [
  {
    id: 'PartnerOne',
    personName: 'Безродній Олександр',
    title: 'Керуючий партнер,\n адвокат',
    description: [
      'Вища юридична та економічна освіта',
      '22 роки досвіду роботи',
    ],
    image: partnerOne,
    imagePlaceholder: partnerOnePlaceholder,
  },
  {
    id: 'PartnerTwo',
    personName: 'Кокольський Сергій',
    title: 'Партнер,\n адвокат',
    description: [
      'Вища юридична\n освіта',
      '22 роки досвіду роботи',
    ],
    image: partnerTwo,
    imagePlaceholder: partnerTwoPlaceholder,
  },
];
